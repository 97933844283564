import React, { useState } from 'react'

const images = [
  'https://placehold.co/800x600?text=Gallery+1',
  'https://placehold.co/800x600?text=Gallery+2',
  'https://placehold.co/800x600?text=Gallery+3',
  'https://placehold.co/800x600?text=Gallery+4',
  'https://placehold.co/800x600?text=Gallery+5',
  'https://placehold.co/800x600?text=Gallery+6',
]

export default function Gallery() {
  const [selectedImage, setSelectedImage] = useState(null)

  return (
    <section className="w-full py-16">
      <div className="container">
        <h2 className="text-center text-3xl font-bold">GALERÍA</h2>
        <div className="mt-10 grid gap-4 sm:grid-cols-2 md:grid-cols-3">
          {images.map((image, index) => (
            <button
              key={index}
              className="group relative aspect-video overflow-hidden rounded-lg"
              onClick={() => setSelectedImage(image)}
            >
              <img
                src={image}
                alt={`Imagen ${index + 1}`}
                className="absolute inset-0 h-full w-full object-cover transition-transform duration-300 group-hover:scale-105"
              />
            </button>
          ))}
        </div>
        {selectedImage && (
          <div
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
            onClick={() => setSelectedImage(null)}
          >
            <div className="max-w-3xl w-full p-4">
              <div className="relative aspect-video">
                <img
                  src={selectedImage}
                  alt="Imagen ampliada"
                  className="h-full w-full object-contain"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

import React from 'react'
import { Globe } from 'lucide-react'

export default function TopNav() {
  return (
    <div className="w-full bg-muted py-2">
      <div className="container flex items-center justify-end gap-4">
        <a href="/contact" className="text-sm text-muted-foreground hover:text-primary">
          +52 55 555 5555
        </a>
        <a href="mailto:contact@example.com" className="text-sm text-muted-foreground hover:text-primary">
          contact@example.com
        </a>
      </div>
    </div>
  )
}

import React from 'react'
import TopNav from '../components/top-nav'
import MainNav from '../components/main-nav'
import HeroCarousel from '../components/hero-carousel'
import AboutSection from '../components/about-section'
import PracticeAreas from '../components/practice-areas'
import Associates from '../components/associates'
import Publications from '../components/publications'
import Gallery from '../components/gallery'
import LogoSection from '../components/logo-section'
import ContactForm from '../components/contact-form'
import PreFooter from '../components/pre-footer'
import Footer from '../components/footer'

function App() {
  return (
    <div className="min-h-screen bg-background font-sans antialiased">
      <TopNav />
      <MainNav />
      <main>
        <HeroCarousel />
        <LogoSection />
        <PracticeAreas />
        <AboutSection />
        <ContactForm />
        <Associates />
        <Publications />
        <Gallery />

      </main>
      <Footer />
    </div>
  )
}

export default App

import React from 'react'

const partners = [
  {
    name: 'CARLOS REQUENA',
    image: 'https://placehold.co/400x400?text=CR',
    role: 'Socio Fundador',
  },
  {
    name: 'LUIS FERNANDO HERNÁNDEZ',
    image: 'https://placehold.co/400x400?text=LFH',
    role: 'Socio',
  },
]

const associates = [
  {
    name: 'GUSTAVO GONZÁLEZ LEYVA',
    role: 'Asociado Senior',
  },
  {
    name: 'GABRIEL RAMOS MENA',
    role: 'Asociado',
  },
]

export default function Associates() {
  return (
    <section className="w-full py-20 bg-stone-900 text-white">
      <div className="container mx-auto px-4">
        {/* Partners Section */}
        <h2 className="text-5xl font-bold text-center mb-16">SOCIOS</h2>
        <div className="grid md:grid-cols-2 gap-16 mb-24">
          {partners.map((partner) => (
            <div key={partner.name} className="flex flex-col items-center text-center">
              <div className="w-64 h-64 mb-6">
                <img
                  src={partner.image}
                  alt={partner.name}
                  className="w-full h-full object-cover rounded-full grayscale"
                />
              </div>
              <h3 className="text-2xl font-bold mb-4">{partner.name}</h3>
              <button className="text-primary-600 hover:text-primary-500 transition-colors">
                VER SEMBLANZA
              </button>
            </div>
          ))}
        </div>

        {/* Associates Section */}
        <h2 className="text-5xl font-bold text-center mb-16">ASOCIADOS</h2>
        <div className="grid md:grid-cols-2 gap-16">
          {associates.map((associate) => (
            <div key={associate.name} className="flex flex-col items-center text-center">
              <h3 className="text-2xl font-bold">{associate.name}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

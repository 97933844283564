import React, { useState } from 'react'

export default function Footer() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    const mailtoLink = `mailto:contact@requena.com?subject=Contact Form&body=Name: ${formData.name}%0D%0AEmail: ${formData.email}%0D%0AMessage: ${formData.message}`
    window.location.href = mailtoLink
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  return (
    <footer className="w-full bg-stone-900 text-stone-300 py-16">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {/* Column 1: Logo and Links */}
          <div className="space-y-6">
            <div className="space-y-4">
              <img 
                src="https://placehold.co/200x50?text=Logo"
                alt="Requena Abogados" 
                className="h-12 w-auto"
              />
              <p className="text-sm">
                Firm specialized in criminal law with recognized professional experience in Mexico
              </p>
            </div>
            <div className="space-y-2">
              <p className="text-sm">CRIMINAL COMPLIANCE</p>
              <p className="text-sm">CRIMINAL LITIGATION</p>
              <p className="text-sm">DIALOGUES FOR YOUR GOOD</p>
              <p className="text-sm">CRISIS MANAGEMENT</p>
            </div>
          </div>

          {/* Column 2: Contact Info */}
          <div className="space-y-6">
            <h3 className="text-white font-semibold uppercase">CONTACT</h3>
            <div className="space-y-2 text-sm">
              <p>Taine 245 Piso 1</p>
              <p>Polanco V Sección</p>
              <p>Miguel Hidalgo, 11560 CDMX</p>
            </div>
            <div className="pt-4">
              <a href="/privacy" className="text-sm hover:text-white transition-colors duration-200">
                Privacy Notice
              </a>
            </div>
          </div>

          {/* Column 3: Contact Form */}
          <div className="md:col-span-1">
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="name" className="text-sm">Name (*)</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  required
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full mt-1 px-3 py-2 bg-white text-stone-900 rounded"
                />
              </div>
              <div>
                <label htmlFor="email" className="text-sm">Email (*)</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full mt-1 px-3 py-2 bg-white text-stone-900 rounded"
                />
              </div>
              <div>
                <label htmlFor="message" className="text-sm">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="4"
                  className="w-full mt-1 px-3 py-2 bg-white text-stone-900 rounded"
                />
              </div>
              <button
                type="submit"
                className="bg-primary-600 text-white px-6 py-2 rounded hover:bg-primary-700 transition-colors duration-200"
              >
                SEND
              </button>
            </form>
          </div>
        </div>
        <div className="mt-8 border-t border-stone-800 pt-8 text-center text-sm text-stone-400">
          <p>&copy; {new Date().getFullYear()} Guerecea Abogados. Todos los derechos reservados.</p>
        </div>
      </div>
    </footer>
  )
}

import React from 'react'

export default function AboutSection() {
  const values = [
    { title: 'Excelencia y Calidad Profesional' },
    { title: 'Capacidad Organizacional' },
    { title: 'Tenacidad y Solidez Ética' },
    { title: 'Humanismo' },
    { title: 'Confidencialidad' },
    { title: 'Capacitación Especializada' },
  ]

  return (
    <section className="relative w-full min-h-screen bg-stone-900 text-white">
      <div className="absolute inset-0">
        <img
          src="https://placehold.co/1920x1080?text=Background"
          alt="Background"
          className="w-full h-full object-cover opacity-20"
        />
      </div>
      
      <div className="relative container mx-auto px-4 py-20">
        <div className="max-w-4xl mx-auto text-center space-y-16">
          {/* Convicciones */}
          <div className="space-y-6">
            <h2 className="text-3xl md:text-4xl font-bold leading-tight">
              TENEMOS PROFUNDA CONVICCIÓN EN EL TRABAJO EN EQUIPO Y LA CAPACITACIÓN PROFESIONAL PERMANENTE
            </h2>
            <p className="text-xl md:text-2xl">
              CONTAMOS CON GRAN EXPERIENCIA Y VALORES ÉTICOS A FIN DE OFRECER SOLUCIONES SATISFACTORIAS A NUESTROS CLIENTES
            </p>
          </div>

          {/* Objetivo */}
          <div className="space-y-4">
            <h3 className="text-2xl font-bold text-primary-600">OBJETIVO</h3>
            <p className="text-lg">
              Brindar satisfacción y confianza en nuestros Clientes a través de servicios legales de alta calidad y ética profesional en los ámbitos del Derecho Penal
            </p>
            <p className="text-lg">
              Responder a los constantes retos de superación y formación profesional ante la complejidad social, creciente evolución legislativa y gran competitividad
            </p>
          </div>

          {/* Valores */}
          <div className="space-y-8">
            <h3 className="text-2xl font-bold text-primary-600">VALORES</h3>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
              {values.map((value, index) => (
                <div key={index} className="text-center">
                  <p className="text-lg font-medium">{value.title}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

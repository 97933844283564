import React, { useState, useEffect } from 'react'
import { ChevronLeft, ChevronRight } from 'lucide-react'

export default function HeroCarousel() {
  const slides = [
    {
      image: "https://placehold.co/1920x1080?text=Slide+1",
      title: "Manejo de Crisis",
      subtitle: "Solución Estratégica",
    },
    {
      image: "https://placehold.co/1920x1080?text=Slide+2",
      title: "Litigación Penal",
      subtitle: "Experiencia y Resultados",
    },
    {
      image: "https://placehold.co/1920x1080?text=Slide+3",
      title: "Compliance Penal",
      subtitle: "Prevención y Control",
    },
  ]

  const [currentSlide, setCurrentSlide] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length)
    }, 5000)
    return () => clearInterval(timer)
  }, [])

  return (
    <div className="relative h-[60vh] overflow-hidden">
      <div className="absolute inset-0">
        <div className="relative h-full w-full">
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`absolute inset-0 h-full w-full transition-opacity duration-1000 ${
                currentSlide === index ? 'opacity-100' : 'opacity-0'
              }`}
            >
              <img
                src={slide.image}
                alt={`Slide ${index + 1}`}
                className="h-full w-full object-cover"
              />
              <div className="absolute inset-0 bg-stone-900/60" />
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="text-center text-white">
                  <h2 className="text-4xl md:text-5xl font-bold mb-4">{slide.title}</h2>
                  <p className="text-xl md:text-2xl">{slide.subtitle}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <button
        className="absolute left-4 top-1/2 -translate-y-1/2 rounded-full bg-stone-800/20 p-3 text-white backdrop-blur-sm transition-all hover:bg-stone-700/30 focus:outline-none focus:ring-2 focus:ring-white"
        onClick={() => setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length)}
      >
        <ChevronLeft className="h-6 w-6" />
      </button>
      <button
        className="absolute right-4 top-1/2 -translate-y-1/2 rounded-full bg-stone-800/20 p-3 text-white backdrop-blur-sm transition-all hover:bg-stone-700/30 focus:outline-none focus:ring-2 focus:ring-white"
        onClick={() => setCurrentSlide((prev) => (prev + 1) % slides.length)}
      >
        <ChevronRight className="h-6 w-6" />
      </button>
    </div>
  )
}

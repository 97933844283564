import React from 'react'

export default function LogoSection() {
  const logos = [
    'https://placehold.co/200x100?text=Logo+1',
    'https://placehold.co/200x100?text=Logo+2',
    'https://placehold.co/200x100?text=Logo+3',
    'https://placehold.co/200x100?text=Logo+4',
  ]

  return (
    <section className="h-[40vh] bg-stone-50 py-8">
      <div className="container mx-auto px-4 h-full flex flex-col justify-center">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 items-center justify-items-center">
          {logos.map((logo, index) => (
            <div key={index} className="w-32 h-10 relative grayscale hover:grayscale-0 transition-all duration-300">
              <img
                src={logo}
                alt={`Cliente ${index + 1}`}
                className="object-contain w-full h-full"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

import React from 'react'
import { Scale, Shield, Users, AlertCircle, Gavel, ShieldCheck } from 'lucide-react'

const areas = [
  {
    icon: Scale,
    title: "Consultoría y Litigación",
    description: "Especializado en derecho penal",
  },
  {
    icon: Shield,
    title: "Compliance Penal",
    description: "Responsabilidad penal de empresas",
  },
  {
    icon: Users,
    title: "Acuerdos y Negociación",
    description: "Acciones estratégicas",
  },
  {
    icon: AlertCircle,
    title: "Manejo de Crisis",
    description: "En contextos de litigación",
  },
  {
    icon: Gavel,
    title: "Derecho Penal",
    description: "Asuntos patrimoniales, financieros y fiscales",
  },
  {
    icon: ShieldCheck,
    title: "Prevención del Delito",
    description: "Asesoría en programas preventivos",
  },
]

export default function PracticeAreas() {
  return (
    <section className="w-full py-12 md:py-16 lg:py-20 bg-stone-100">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl md:text-4xl font-bold text-center text-stone-900">ÁREAS DE ESPECIALIZACIÓN</h2>
        <div className="mt-10 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {areas.map((area) => {
            const Icon = area.icon
            return (
              <div key={area.title} className="flex flex-col items-center text-center p-6 rounded-lg bg-white shadow-lg hover:shadow-xl transition-shadow duration-300">
                <div className="p-3 rounded-full bg-primary-50">
                  <Icon className="h-8 w-8 text-primary-600" />
                </div>
                <h3 className="mt-4 text-xl font-semibold text-stone-800">{area.title}</h3>
                <p className="mt-2 text-stone-600">{area.description}</p>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
